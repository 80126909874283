<template>
  <div>
    <div
      class=" font-weight-light"
      :class="{'pa-5 headline': $vuetify.breakpoint.lgAndUp,'pa-2 subtitle-1': $vuetify.breakpoint.mdOnly, 'pa-1 body-2': $vuetify.breakpoint.smAndDown}"
    >
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span style="font-family:Oswald"> Combustion Reactions </span>
      </div>
      <div style="margin-top: 10px" />
      Combustion reactions are chemical reactions in which a substance reacts with oxygen to produce heat and usually light. The most common example of a combustion reaction is the burning of a hydrocarbon fuel such as methane, which releases energy in the form of heat and light. <br><br>
      In general, combustion reactions are exothermic &mdash; meaning they release energy in form of heat and light. <br><br>
      Let's consider the combustion of a hydrocarbon called Methane &mdash; the chemical formula for which is \(CH_4\).
      During combustion of \(CH_4\) (Methane), it reacts with \(O_2\) (Oxygen) and produces \(CO_2\) (Carbon-dy-Oxide) and \(H_2O\) (Water). <br><br> So, the chemical equation describing this reaction looks something like this &mdash;
      $$a \ CH_4 + b\ O_2 \rightarrow  c\ CO_2 + d\ H_2O$$
      Here \(a\), \(b\), \(c\) and \(d\) are coefficients of the various compounds involved in the reaction.
      The goal of balancing the equation is to find the values of these coefficients such that the number of atoms of each chemical species (i.e. \(C\), \(H\) and \(O\)) remains same on the two sides of the equation.
      <div style="margin-top: 10px" />
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <span id="mgk" ref="pg" style="font-family:Oswald"> Learn interactively : Balancing a Combustion Reaction </span>
      </div>
      <div style="margin-top: 10px" />
      <h5>Session Objectives</h5>
      <div style="margin-top: 10px" />
      In this visually interactive illustration, we learn how to balance a reaction representing combustion of hydrocarbons in step-by-step fashion. <br><br> Shown in the MagicGraph is a chemical equation that describes the combustion of a hydrocarbon. During combustion, the hydrocarbon molecule, say \(CH_4\), reacts with \(O_2\) and produces \(CO_2\) and \(H_2O\).<br><br>
      You will notice that the coefficients of the reactants and products are missing. The goal is determine the right set of coefficients that balance the chemical equation.
      <div style="margin-top: 10px" />
      <h5>Getting Started</h5>
      <div style="margin-top: 10px" />
      To get started &mdash; <br><br>
      1. Start with entering the coefficients for the reactants and the products in the chemical equation.</br><br>
      2. Once you have provided the coefficients, the MagicGraph automatically calculates the number of moles of each chemical element on the reactant side as well as on the product side. <br><br>
      3. The chemical equation is balanced if the mass of each chemical element before the reaction is same as the mass after the reaction.<br><br>
      4. You can adjust the coefficients to balance the mass of each chemical element before and after the reaction.
    </div>
    <div style="margin-top: 10px" />
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
      <v-layout justify-center>
        <h5 class="edliy-text-about">
          Navigation Tip: Tap on <i class="shuffler ma-1" /> icon to shuffle. Tap on <i class="eraser ma-1" /> icon to clear your response and start over.
        </h5>
      </v-layout>
    </v-responsive>
    <br><br>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'BalancedEquation',
  components: {
  },
  data () {
    return {
      style: {layout: "margin: 10px"},
    }
  },
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Combustion Reactions';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is a Chemical Equation?',img:'/assets/number-1.svg', action: () => this.goto('def')},
      {title: 'Reactants and Products', img:'/assets/number-2.svg', action: () => this.goto('pg')},
      {title: 'Balanced versus unbalanced Equation',img:'/assets/number-3.svg', action: () => this.goto('pg')},
      {title: 'Balancing a Chemical Equation', img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    this.$store.commit('navigation/toggleshowhome', false);
    this.$store.commit('navigation/replaceChem', true);
    this.$store.commit('navigation/replaceLeftArrow', true);
    this.$store.commit('navigation/replaceModule', true);
    this.$store.commit('navigation/replaceSample', true);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
    Boxes.box3();
  },
  metaInfo() {
  return{ title: 'Combustion Reactions',
          titleTemplate: '%s | Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Balancing a chemical equation.'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
.icn{
color: var(--v-red-base);;
}
</style>

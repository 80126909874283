import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeDash,
    placeCircle,
    placeLogo,
    placeShuffle,
    placeErase,
    placePlay,
    placePause,
    placeTest,
    cycle,
    writeMiddleText,
    writeLeftText,
    writeRightText,
    writeMiddleHTML,
    writeLeftHTML,
    writeRightHTML,
    placeGo,
    placeStartOver
} from '../../Utils.js';
const Boxes = {
  box1: function(){
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [0, 6, 12, -6],
      keepaspectratio:true, axis:false, ticks:true, grid:true, showCopyright:false, showNavigation:false,pan:{enabled:false}, zoom:{enabled:false}});
    brd1.suspendUpdate();
    placeLogo(brd1);
    makeResponsive(brd1);
    var shuffle =placeShuffle(brd1,'left');
    var erase = placeErase(brd1);
    var list1=['CH_4', 'C_2H_6', 'C_3H_8','C_4H_1_0'];
    var list=['Methane', 'Ethane', 'Propane','Butane'];
    var i=0;
  //  shuffle.setLabel('Shuffle');
    //shuffle.label.setAttribute({visible:true, anchorX:'left', offset:[0, -10], CssStyle:'fontFamily:Oswald', fontSize:function(){return 12*brd1.canvasHeight/800}});
  //  shuffle.on('over', function () {this.label.setAttribute({visible:true});});
  //  shuffle.on('out', function () {this.label.setAttribute({visible:false});});
    //erase.on('down', function(){clearInputFields(brd1)});
  //  erase.setLabel('Start Over');
  //  erase.label.setAttribute({visible:true, anchorX:'left', offset:[0, -10], CssStyle:'fontFamily:Oswald', fontSize:function(){return 12*brd1.canvasHeight/800}});
  //  erase.on('over', function () {this.label.setAttribute({visible:true});});
  //  erase.on('out', function () {this.label.setAttribute({visible:false});});
////////////////////////////////////////////////////////////////////////////////////////////////////
//    brd1.create('text', [6, 5., function(){return 'Combustion of '+ list[i.valueOf()]}], {highlight:false, anchorX:'middle', highlight:false, fixed:true, fontSize:function(){return 32*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;fontWeight:bold'});
  //
    let title =placeTitle(brd1, function(){return 'Combustion of '+ list[i.valueOf()]}, "(Enter the missing coefficients to balance the equation)");
////////////////////
    var Cmethane = brd1.create('input', [0.85, 3.35, '0', ' '], {cssStyle:'width:8.3333%;', fontSize:function(){return 24*brd1.canvasHeight/800}, fixed:true,parse:true});

    //placeTitle(brd1, function(){return 'Oxidation of '+ list[i.valueOf()]});
    brd1.create('text', [1.85, 3.35, function(){return '&nbsp;' + list1[i.valueOf()]+' +'}], {highlight:false, anchorX:'left', fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    //////////////////////////////////////////
    var Coxy = brd1.create('input', [3, 3.35, '0', ' '], {cssStyle: 'width:8.33333%;', fontSize:function(){return 24*brd1.canvasHeight/800}, fixed:true,parse:true});
    brd1.create('text', [4, 3.35, '&nbsp; O_2'], {highlight:false, fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    brd1.create('image',['/assets/random.svg', [5.75, 2.7], [1., 1]],{rotate:45, highlight:false, fixed:true});
    //brd1.create('arrow', [[5, 3.35],[6, 3.35]], {highlight:false,strokeColor:'black', strokeWidth:function(){return 5*brd1.canvasHeight/800}, fixed:true});
    var Ccotwo = brd1.create('input', [7, 3.35, '0', ' '], {cssStyle: 'width:8.333333%', fontSize:function(){return 24*brd1.canvasHeight/800}, fixed:true,parse:true});
    brd1.create('text', [8, 3.35, '&nbsp; CO_2 +'], {highlight:false,fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    var Cwater = brd1.create('input', [9, 3.35, '0', ' '], {cssStyle: 'width:8.3333333333%;', fontSize:function(){return 24*brd1.canvasHeight/800}, fixed:true,parse:true});
    brd1.create('text', [10, 3.35, '&nbsp; H_2O'], {highlight:false,fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//var dsa =brd.create('slider', [[2, 4],[6, 4], [1, 2, 8]]);
//Mass Budget
    brd1.create('line', [[1, 2.],[5, 2.]], {highlight:false,strokeColor:'grey', strokeWidth:1,fixed:true});
    brd1.create('line', [[1, 2.05],[5, 2.05]], {highlight:false,strokeColor:'grey', strokeWidth:1,fixed:true});
    brd1.create('text', [2.5, 1.5, '<b> REACTANT SIDE</b>'], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    brd1.create('text', [8.5, 1.5, '<b> PRODUCT SIDE </b>'], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    brd1.create('line', [[1, 1.],[5, 1.]], {highlight:false,strokeColor:'grey', strokeWidth:1,fixed:true});
    brd1.create('line', [[1, 1.05],[5, 1.05]], {highlight:false,strokeColor:'grey', strokeWidth:1, fixed:true});
// REACTION SIDE
    brd1.create('text', [2.5, 0.5, function(){return (i.valueOf()+1)*Cmethane.Value() + ' Moles of C'}], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:	'fontFamily:Oswald;'});
    brd1.create('image', ['/assets/check.svg', [9.6, 0.1], [0.8,0.8]],{highlight:false, opacity:1, fixed:true, visible:function(){return 1*Ccotwo.Value()==(i.valueOf()+1)*Cmethane.Value() && 1*Cmethane.Value()>0} });
    //brd1.create('image', ['/assets/cross.svg', [9.6, 0.1], [0.8,0.8]],{highlight:false, opacity:1, fixed:true, visible:function(){return 1*Ccotwo.Value()!=(i.valueOf()+1)*Cmethane.Value() && 1*Cmethane.Value()>0} });
    brd1.create('text', [2.5, -0.5, function(){return 2* Coxy.Value() + ' Moles of O'}], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    brd1.create('image', ['/assets/check.svg', [9.6, -0.9], [0.8,0.8]],{highlight:false, opacity:1, fixed:true, visible:function(){return 2*Coxy.Value() == 1*Cwater.Value()+2*Ccotwo.Value()&& 1*Coxy.Value()>0} });

    brd1.create('text', [2.5, -1.5, function(){return (2*(i.valueOf()+1)+2)*Cmethane.Value() + ' Moles of H'}], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    brd1.create('image', ['/assets/check.svg', [9.6, -1.9], [0.8,0.8]],{highlight:false, opacity:1, fixed:true, visible:function(){return 2*Cwater.Value() == (2*(i.valueOf()+1)+2)*Cmethane.Value()&& 1*Cwater.Value()>0} });
// PRODUCT SIDE
    brd1.create('text', [8.5, 0.5, function(){return 1*Ccotwo.Value() + ' Moles of C'}], {anchorX:'middle',highlight:false, anchorY:'middle',fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    brd1.create('text', [8.5, -0.5, function(){return 1*Cwater.Value()*1+2*Ccotwo.Value()+ ' Moles of O'}], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    brd1.create('text', [8.5, -1.5, function(){return 2* Cwater.Value() + ' Moles of H'}], {anchorX:'middle',highlight:false,anchorY:'middle', fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    brd1.create('line', [[1, -2],[5, -2]], {highlight:false, strokeColor:'grey', strokeWidth:1, fixed:true});
    brd1.create('line', [[1, -1.95],[5, -1.95]], {highlight:false, strokeColor:'grey', strokeWidth:1, fixed:true});
    brd1.create('text', [6, -2.5,'Balanced or Not Balanced?'],{anchorX:'middle', anchorY:'middle', highlight:false,fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    var res = brd1.create('text', [6, -3.5, function(){if(1*Ccotwo.Value()==(i.valueOf()+1)*Cmethane.Value() && 2*Coxy.Value() == 1*Cwater.Value()+2*Ccotwo.Value() && 2*Cwater.Value() == (2*(i.valueOf()+1)+2)*Cmethane.Value()){return 'The Equation is Now Balanced'} else {return 'The Equation is Not Yet Balanced'}}], {anchorX:'middle', anchorY:'middle', highlight:false,fontSize:function(){if(Cmethane.Value()==0){return 0} else{return 24*brd1.canvasHeight/800}}, cssStyle:'fontFamily:Oswald;', fixed:true});
    shuffle.on('down', function(){return i=cycle(i,3)});
    shuffle.on('down', function(){
      let magicGraph = brd1.containerObj;
      let inputFields = magicGraph.querySelectorAll("input");
          for (let inputField of inputFields) {
                inputField.value = "0";}
    });
    erase.on('down', function(){
      let magicGraph = brd1.containerObj;
      let inputFields = magicGraph.querySelectorAll("input");
          for (let inputField of inputFields) {
                inputField.value = "0";}
    });
    brd1.unsuspendUpdate();
  },
  box2: function () {
    var brd2 = JXG.JSXGraph.initBoard('jxgbox2',{boundingbox: [0, 8, 12, -4],
      keepaspectratio:true, axis:false, ticks:true, grid:true, showCopyright:false, showNavigation:false,pan:{enabled:false}, zoom:{enabled:false}});
    placeLogo(brd2);
    makeResponsive(brd2);
    var shuffle =placeShuffle(brd2,'left');
    var erase = placeTest(brd2);
    var list=['C_2H_4', 'C_3H_6', 'C_4H_8','C_5H_1_0'];
    var c = ['2', '3', '4', '5'];
    var Oxy = [3, 9/2, 6, 6];
    var i=0; var vis=0;
    shuffle.setLabel('Tap to Shuffle')
    shuffle.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:function(){return 12*brd2.canvasHeight/800}});
    shuffle.on('over', function () {this.label.setAttribute({visible:true});});
    shuffle.on('out', function () {this.label.setAttribute({visible:false});});
/////////////////////////////////////////////////
    erase.setLabel('Check Your Answer')
    erase.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:function(){return 12*brd2.canvasHeight/800}});
    erase.on('over', function () {this.label.setAttribute({visible:true});});
    erase.on('out', function () {this.label.setAttribute({visible:false});});
////////////////////////////////////////////////////////////////////////////////////////
    brd2.create('text', [6, 7.5, function(){return 'Oxidation of '+ list[i.valueOf()]}], {highlight:false, anchorX:'middle', highlight:false, fixed:true, fontSize:function(){return 32*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;fontWeight:bold'});
    //
    var title =placeTitle(brd2, "", "(Enter the missing coefficients to balance the equation)");
  //
    brd2.create('text', [1.85, 5.5, function(){return '&nbsp;' + list[i.valueOf()]+'&nbsp;&nbsp;+'}], {highlight:false, anchorX:'left', fixed:true, fontSize:function(){return 24*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
    brd2.create('text', [4, 5.5, ()=>Oxy[i.valueOf()].toString()+' &nbsp;'], {highlight:false, anchorX:'right', fixed:true, fontSize:function(){return 24*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
    brd2.create('text', [4, 5.5, '&nbsp; O_2'], {highlight:false, fixed:true, fontSize:function(){return 24*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    brd2.create('image',['/assets/random.svg', [5.75, 4.7], [1., 1]],{rotate:45, highlight:false, fixed:true});
//
    var Ccotwo = brd2.create('input', [7, 5.5, '0', ' '], {cssStyle: 'width:8.333333%', fontSize:function(){return 24*brd2.canvasHeight/800}, fixed:true,parse:true});
    Ccotwo.on('down', function(){vis=0});
//
    brd2.create('text', [8, 5.5, '&nbsp; CO_2 +'], {highlight:false,fixed:true, fontSize:function(){return 24*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
    var Cwater = brd2.create('input', [9, 5.5, '0', ' '], {cssStyle: 'width:8.3333333333%;', fontSize:function(){return 24*brd2.canvasHeight/800}, fixed:true,parse:true});
//
    brd2.create('text', [10, 5.5, '&nbsp; H_2O'], {highlight:false,fixed:true, fontSize:function(){return 24*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    //
    brd2.create('line', [[1, 2.],[5, 2.]], {visible:()=>vis==2,highlight:false,strokeColor:'grey', strokeWidth:1,fixed:true});
    //
    brd2.create('line', [[1, 2.05],[5, 2.05]], {visible:()=>vis==2,highlight:false,strokeColor:'grey', strokeWidth:1,fixed:true});
    //
    brd2.create('text', [2.5, 1.5, '<b> REACTANT SIDE</b>'], { anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}).setAttribute({visible:()=>vis==2});;
    //
    brd2.create('text', [8.5, 1.5, '<b> PRODUCT SIDE </b>'], {anchorX:'middle',highlight:false, anchorY:'middle',fixed:false, fontSize:function(){return 24*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}).setAttribute({visible:()=>vis==2});
    //
    brd2.create('line', [[1, 1.],[5, 1.]], {highlight:false,strokeColor:'grey', strokeWidth:1,fixed:true}).setAttribute({visible:()=>vis==2});;
    //
    brd2.create('line', [[1, 1.05],[5, 1.05]], {display:'internal', highlight:false,strokeColor:'grey', strokeWidth:1, fixed:true}).setAttribute({visible:()=>vis==2});;
// REACTION SIDE
    brd2.create('text', [2.5, 0.5, function(){return c[i.valueOf()] + ' Moles of C'}], { anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd2.canvasHeight/800}, cssStyle:	'fontFamily:Oswald;'}).setAttribute({visible:()=>vis==2});
//
    brd2.create('image', [function(){if(1*Ccotwo.Value()==c[i.valueOf()]){return '/assets/check.svg'}else{return '/assets/cross.svg'}}, [9.6, 0.1], [0.8,0.8]],{highlight:false, opacity:1, fixed:true}).setAttribute({visible:()=>vis==2});
//
    brd2.create('text', [2.5, -0.5, function(){return 2.0*Oxy[i.valueOf()]*1 + ' Moles of O'}], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}).setAttribute({visible:()=>vis==2});
//
    brd2.create('image', [function(){if(2*Oxy[i.valueOf()] == 1*Cwater.Value()+2*Ccotwo.Value()){return '/assets/check.svg'}else{return '/assets/cross.svg'}}, [9.6, -0.9], [0.8,0.8]],{highlight:false, opacity:1, fixed:true}).setAttribute({visible:()=>vis==2});
//
    brd2.create('text', [2.5, -1.5, function(){return (2*(i.valueOf()+1)+2)+ ' Moles of H'}], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}).setAttribute({visible:()=>vis==2});
    //
    brd2.create('image', [function(){if(2*Cwater.Value() == (2*(i.valueOf()+1)+2)*1){return '/assets/check.svg'}else{return '/assets/cross.svg'}}, [9.6, -1.9], [0.8,0.8]],{highlight:false, opacity:1, fixed:true}).setAttribute({visible:()=>vis==2});
// PRODUCT SIDE
    brd2.create('text', [8.5, 0.5, function(){return 1*Ccotwo.Value() + ' Moles of C'}], {anchorX:'middle',highlight:false, anchorY:'middle',fixed:true, fontSize:function(){return 24*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}).setAttribute({visible:()=>vis==2});
    //
    brd2.create('text', [8.5, -0.5, function(){return 1*Cwater.Value()*1+2*Ccotwo.Value()+ ' Moles of O'}], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}).setAttribute({visible:()=>vis==2});
//
    brd2.create('text', [8.5, -1.5, function(){return 2* Cwater.Value() + ' Moles of H'}], {anchorX:'middle',highlight:false,anchorY:'middle', fixed:true, fontSize:function(){return 24*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}).setAttribute({visible:()=>vis==2});
    //
    brd2.create('line', [[1, -2],[5, -2]], {highlight:false, strokeColor:'grey', strokeWidth:1, fixed:true}).setAttribute({visible:()=>vis==2});
    //
    brd2.create('line', [[1, -1.95],[5, -1.95]], {highlight:false, strokeColor:'grey', strokeWidth:1, fixed:true}).setAttribute({visible:()=>vis==2});
    //
    var res = brd2.create('text', [6, 2.5, function(){if(1*Ccotwo.Value()==(i.valueOf()+2) && 2*3 == 1*Cwater.Value()+2*Ccotwo.Value() && 2*Cwater.Value() == (2*(i.valueOf()+2))){return 'Yes. The Equation is Balanced'} else {return 'No. The Equation is Not Balanced'}}], {anchorX:'middle', anchorY:'middle', highlight:false, fontSize:function(){return 24*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;', fixed:true}).setAttribute({visible:()=>vis==2});

    shuffle.on('down', function(){return i=cycle(i,3)});
    shuffle.on('down', function(){
      vis=0;
      let magicGraph = brd2.containerObj;
      let inputFields = magicGraph.querySelectorAll("input");
          for (let inputField of inputFields) {
                inputField.value = "0";}
    });
    erase.on('down', function(){vis=1;
    });
  },
  box3: function () {
    let brd3 = JXG.JSXGraph.initBoard('jxgbox3',{boundingbox: [0, 8, 12, -4],
      keepaspectratio:true, axis:false, ticks:true, grid:true, showCopyright:false, showNavigation:false,pan:{enabled:false}, zoom:{enabled:false}});
    placeLogo(brd3);
    makeResponsive(brd3);
    let shuffle =placeGo(brd3,'left');
    let erase = placeTest(brd3);
    let startOver = placeStartOver(brd3,'left');
    //
    let i=0; let vis=0; let jis=0;
    shuffle.setLabel('Submit Answer');
    shuffle.setAttribute({visible:()=>vis<1});
    shuffle.label.setAttribute({visible:()=>vis<1, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:function(){return 12*brd3.canvasHeight/800}});
  /////////////////////////////////////////////////
    erase.setLabel('Explain Why');
    erase.setAttribute({visible:()=>vis==1});
    erase.label.setAttribute({visible:()=>vis==1, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:function(){return 12*brd3.canvasHeight/800}});
/////////////////////////////////////////////
    startOver.setLabel('Start Over')
    startOver.setAttribute({visible:()=>vis==2});
    startOver.label.setAttribute({visible:()=>vis==2, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:function(){return 12*brd3.canvasHeight/800}});
///////////////////////////////////////////////////////////
//  brd3.create('text', [6, 7.5, 'Rusting of Iron'], {fixed:true, fontSize:function(){return 32*brd3.canvasHeight/800},cssStyle:'fontFamily:Oswald;fontWeight:bold'})
  //
  let title =placeTitle(brd3, 'Rusting of Iron', '(Enter the missing coefficients to balance the equation)');
// Reactants
  let Fe = brd3.create('input', [1, 5.5, '?', ' '], {cssStyle: 'width:8.333333%', fontSize:function(){return 24*brd3.canvasHeight/800}, fixed:true,parse:true});
//
  writeLeftHTML(brd3, 2, 5.5, function(){return '&nbsp; Fe &nbsp; +'}).setAttribute({anchorY:'middle'});
//
    let O = brd3.create('input', [3, 5.5, '?', ' '], {cssStyle: 'width:8.333333%', fontSize:function(){return 24*brd3.canvasHeight/800}, fixed:true,parse:true});
//
//    brd3.create('text', [4, 5.5, ()=>Oxy[i.valueOf()].toString()+' &nbsp;'], {highlight:false, anchorX:'right', fixed:true, fontSize:function(){return 24*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
  //
    brd3.create('text', [4, 5.5, '&nbsp; O_2 &nbsp;+'], {highlight:false, fixed:true, anchorX:'left', fontSize:function(){return 24*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
    brd3.create('text', [6, 5.5, '&nbsp; H_2O'], {highlight:false, fixed:true, anchorX:'left', fontSize:function(){return 24*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
    brd3.create('image',['/assets/random.svg', [8, 4.7], [1., 1]],{rotate:45, highlight:false, fixed:true});
  //
    let H2O = brd3.create('input', [5, 5.5, '?', ' '], {cssStyle: 'width:8.333333%', fontSize:function(){return 24*brd3.canvasHeight/800}, fixed:true,parse:true});
  //  Ccotwo.on('down', function(){vis=0});
  //
    let FeOH3 = brd3.create('input', [9, 5.5, '?', ' '], {cssStyle: 'width:8.3333333333%;', fontSize:function(){return 24*brd3.canvasHeight/800}, fixed:true,parse:true});
  //
  // Diagnostics
    brd3.create('text', [6, 4.5,'Balanced or Not Balanced?'],{anchorX:'middle', anchorY:'middle', highlight:false,fixed:true, fontSize:function(){return 24*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

    brd3.create('text', [10, 5.5, '&nbsp; Fe(OH)_3'], {highlight:false,fixed:true, fontSize:function(){return 24*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    //
    brd3.create('line', [[1, 2.],[5, 2.]], {visible:()=>vis==2,highlight:false,strokeColor:'grey', strokeWidth:1,fixed:true});
    //
    brd3.create('line', [[1, 2.05],[5, 2.05]], {visible:()=>vis==2,highlight:false,strokeColor:'grey', strokeWidth:1,fixed:true});
    //
    brd3.create('text', [2.5, 1.5, '<b> REACTANT SIDE</b>'], { anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}).setAttribute({visible:()=>vis==2});;
    //
    brd3.create('text', [8.5, 1.5, '<b> PRODUCT SIDE </b>'], {anchorX:'middle',highlight:false, anchorY:'middle',fixed:false, fontSize:function(){return 24*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}).setAttribute({visible:()=>vis==2});
    //
    brd3.create('line', [[1, 1.],[5, 1.]], {highlight:false,strokeColor:'grey', strokeWidth:1,fixed:true}).setAttribute({visible:()=>vis==2});;
    //
    brd3.create('line', [[1, 1.05],[5, 1.05]], {display:'internal', highlight:false,strokeColor:'grey', strokeWidth:1, fixed:true}).setAttribute({visible:()=>vis==2});;
  // REACTION SIDE
    brd3.create('text', [2.5, 0.5, function(){return 1*Fe.Value() + ' Moles of Fe'}], { anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd3.canvasHeight/800}, cssStyle:	'fontFamily:Oswald;'}).setAttribute({visible:()=>vis==2});
  //
    brd3.create('image', [function(){if(1*Fe.Value()==1*FeOH3.Value()){return '/assets/check.svg'}else{return '/assets/cross.svg'}}, [10.1, 0.1], [0.8,0.8]],{highlight:false, opacity:1, fixed:true}).setAttribute({visible:()=>vis==2});
  //
    brd3.create('text', [2.5, -0.5, function(){return 2.0*O.Value()+1*H2O.Value() + ' Moles of O'}], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}).setAttribute({visible:()=>vis==2});
  //
    brd3.create('image', [function(){if(2.0*O.Value()+1*H2O.Value() == 3*FeOH3.Value()){return '/assets/check.svg'}else{return '/assets/cross.svg'}}, [10.1, -0.9], [0.8,0.8]],{highlight:false, opacity:1, fixed:true}).setAttribute({visible:()=>vis==2});
  //
    brd3.create('text', [2.5, -1.5, function(){return 3*FeOH3.Value()+ ' Moles of H'}], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}).setAttribute({visible:()=>vis==2});
    //
    brd3.create('image', [function(){if(2*H2O.Value() == 3*FeOH3.Value()){return '/assets/check.svg'}else{return '/assets/cross.svg'}}, [10.1, -1.9], [0.8,0.8]],{highlight:false, opacity:1, fixed:true}).setAttribute({visible:()=>vis==2});
  // PRODUCT SIDE
    brd3.create('text', [8.5, 0.5, function(){return 1*FeOH3.Value() + ' Moles of Fe'}], {anchorX:'middle',highlight:false, anchorY:'middle',fixed:true, fontSize:function(){return 24*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}).setAttribute({visible:()=>vis==2});
    //
    brd3.create('text', [8.5, -0.5, function(){return 3*FeOH3.Value()+ ' Moles of O'}], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}).setAttribute({visible:()=>vis==2});
  //
    brd3.create('text', [8.5, -1.5, function(){return 3*FeOH3.Value() + ' Moles of H'}], {anchorX:'middle',highlight:false,anchorY:'middle', fixed:true, fontSize:function(){return 24*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}).setAttribute({visible:()=>vis==2});
    //
    brd3.create('line', [[1, -2],[5, -2]], {highlight:false, strokeColor:'grey', strokeWidth:1, fixed:true}).setAttribute({visible:()=>vis==2});
    //
    brd3.create('line', [[1, -1.95],[5, -1.95]], {highlight:false, strokeColor:'grey', strokeWidth:1, fixed:true}).setAttribute({visible:()=>vis==2});
    //
    //Decision
    brd3.create('text', [6, 2.5, 'Enter all the missing coefficients.'],
      {highlight:false, fontSize:function(){return 24*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;',fixed:true}).setAttribute({anchorX:'middle', visible:()=>jis!=0});
  //
    brd3.create('text', [6, 2.5, function(){
      if(Fe.Value()==FeOH3.Value() && 3*FeOH3.Value() == 2*O.Value()+1*H2O.Value() && 2*H2O.Value() == 3*FeOH3.Value()){return 'Yes. The Equation is Balanced.'} else {return 'No. The Equation is Not Balanced.'}}], {anchorX:'middle', anchorY:'middle', highlight:false, fontSize:function(){return 24*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;', fixed:true}).setAttribute({visible:()=>vis!=0});
//
    startOver.on('down', function(){vis=0;
        let magicGraph = brd3.containerObj;
        let inputFields = magicGraph.querySelectorAll("input");
              for (let inputField of inputFields) {
                  inputField.value = "?";} });
//
    shuffle.on('down', function(){
      if(Fe.Value()=='?'|| O.Value()=='?' || H2O.Value()=='?' || FeOH3.Value()=='?'){jis=1;}
      else {
      vis=1; jis=0;
      }
  //    let magicGraph = brd3.containerObj;
  //    let inputFields = magicGraph.querySelectorAll("input");
  //        for (let inputField of inputFields) {
  //              inputField.value = "0";}
    });
    erase.on('down', function(){
      if(Fe.Value()=='?'|| O.Value()=='?' || H2O.Value()=='?' || FeOH3.Value()=='?'){jis=1;}
      else {
      vis=2; jis=0;
      }
    });
  }
}
export default Boxes;
